import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './class-component-hooks'

import Buefy from 'buefy'

import { ITranslationService, serviceLocatorInstance } from '@/domains/common/services'
import '@/domains/common/utils/directives'
import { toClipboard } from './directives/to-clipbopard'

Vue.config.productionTip = false

// 3rd-party plugins
Vue.use(Buefy, {
    defaultIconPack: 'fas'
})

Vue.directive('to-clipboard', toClipboard)

new Vue({
    router,
    i18n: serviceLocatorInstance.get<ITranslationService>('translation').getVuePlugin(),
    render: (h) => h(App)
}).$mount('#app')
