
import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import { FeatureCollection, GeoJsonProperties } from 'geojson'
import { DateTime } from 'luxon'

import { MapBoxGL } from '../MapBoxGL'
import BaseMapComponent from '../BaseMap.vue'
import { DMAFeatureState, GroupMapController } from './GroupMapController'

import { LegendRepresentation } from '@/domains/common/models'
import { EngineEventType, MapKpis } from '@/domains/detect/models'
import { buildDmaPopup } from './MapPopupHelper'

export const NO_ENGINE = 'NO_ENGINE'

export type KpisValues = {
    [key: string]: { [key: string]: string | number | boolean | DateTime | null | 'NO_ENGINE' }
} | null

@Component
export default class GroupMapComponent extends BaseMapComponent<GroupMapController> {
    @Prop({ type: Object, required: false, default: null }) readonly dmas!: FeatureCollection | null
    @Prop({ type: Object, required: false, default: null }) readonly sensors!: FeatureCollection | null

    @Prop({ type: Object, required: false, default: () => ({}) }) readonly kpisLegends!: MapKpis
    @Prop({ type: String, required: false, default: null }) readonly kpiSelected!: string | null
    @Prop({ type: Object, required: false, default: null }) readonly kpis!: KpisValues

    @Prop({ type: Number, required: false, default: 100 }) readonly zoomPadding!: number
    @Prop({ type: Number, required: false, default: 10 }) readonly sensorZoomLevel!: number

    eventTypes: { [EngineEventType: string]: string } = {
        [EngineEventType.BURST]: 'model.engine.event.type.burst',
        [EngineEventType.CUMULATIVE_LEAK]: 'model.engine.event.type.cumulative_leak',
        [EngineEventType.LOW]: 'model.engine.event.type.low_leak',
        [EngineEventType.MEDIUM]: 'model.engine.event.type.medium'
    }

    @Watch('dmas', { immediate: true })
    onDmasChange(dmas: FeatureCollection | null): void {
        this.controller?.displayDmas(dmas, this.zoomPadding)
        this.controller?.updateKpiSelected(dmas, this.kpiSelected)
        this.controller?.updateKpis(dmas, this.kpis)

        if (dmas && dmas.features.length > 0) {
            this.initialCenter = MapBoxGL.centerOfFeatures(dmas)
        }
    }

    @Watch('sensors', { immediate: true })
    onSensorsChange(sensors: FeatureCollection | null): void {
        this.controller?.displaySensors(sensors)
        if (sensors && sensors.features.length > 0) {
            this.initialCenter = MapBoxGL.centerOfFeatures(sensors)
        }
    }

    @Watch('kpiSelected', { immediate: true })
    onKpiSelectedChange(newKpiSelected: string | null): void {
        this.controller?.updateKpiSelected(this.dmas, newKpiSelected)
    }

    @Watch('kpis', { immediate: true })
    onAlarmsChange(kpis: KpisValues): void {
        this.controller?.updateKpis(this.dmas, kpis)
    }

    @Emit('dma-selected')
    onDMASelected(dmaId: string): string {
        return dmaId
    }

    @Emit('sensor-selected')
    onSensorSelected(sensor: string): string {
        return sensor
    }

    protected getController(mapBoxGL: MapBoxGL): GroupMapController {
        return new GroupMapController(
            mapBoxGL,
            this.getMapKpisValues(),
            this.sensorZoomLevel,
            this.buildPopup,
            this.onDMASelected,
            this.onSensorSelected
        )
    }

    protected updateMap(): void {
        this.controller?.displayDmas(this.dmas, this.zoomPadding, false)
        this.controller?.displaySensors(this.sensors, this.zoomPadding, false)
        this.controller?.focusOnPOIs(
            {
                type: 'FeatureCollection',
                features: [...(this.dmas?.features || []), ...(this.sensors?.features || [])]
            },
            this.zoomPadding
        )
        this.controller?.updateKpiSelected(this.dmas, this.kpiSelected)
        this.controller?.updateKpis(this.dmas, this.kpis)
    }

    private buildPopup(properties: GeoJsonProperties, state: DMAFeatureState): string | null {
        return buildDmaPopup.bind(this)(properties, state, this.kpisLegends, this.eventTypes)
    }

    private getMapKpisValues(): LegendRepresentation[] {
        return Object.values(this.kpisLegends)
    }
}
