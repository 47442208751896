import { Geometry } from 'geojson'
import { reduce } from 'lodash'
import { BaseDma } from './BaseDma'
import { Connection } from './Connection'
import { Engines, ProviderType } from './Engines'
import { Sensor } from './Sensor'
import { EngineConfiguration } from './EngineConfiguration'

export class Dma extends BaseDma {
    private _zoneId: string
    private _externalId: string
    private _timezone: string
    private _pipeLength: number
    private _timeStep: number
    private _averageWindow: number
    private _gracePeriod: number
    private _inhabitantsCount: number | null
    private _houseConnections: number | null
    private _minimumNightFlow: number | null
    private _nightflowStartHour: string | null
    private _nightflowEndHour: string | null
    private _geometry: Geometry
    private _connectionsMap: Map<string, Connection<Sensor>>
    private _engines: Engines
    private _normalizedConnectionsMaps: Map<string, Connection<Sensor>>

    constructor(
        id: string,
        name: string,
        description: string,
        active: boolean,
        zoneId: string,
        externalId: string,
        timezone: string,
        pipeLength: number,
        timeStep: number,
        averageWindow: number,
        gracePeriod: number,
        inhabitantsCount: number | null,
        houseConnections: number | null,
        minimumNightFlow: number | null,
        nightflowStartHour: string | null,
        nightflowEndHour: string | null,
        geometry: Geometry,
        connections?: Map<string, Connection<Sensor>>,
        engines?: Engines
    ) {
        super(id, name, description, active)
        this._zoneId = zoneId
        this._externalId = externalId
        this._timezone = timezone
        this._pipeLength = pipeLength
        this._timeStep = timeStep
        this._averageWindow = averageWindow
        this._gracePeriod = gracePeriod
        this._inhabitantsCount = inhabitantsCount
        this._houseConnections = houseConnections
        this._minimumNightFlow = minimumNightFlow
        this._nightflowStartHour = nightflowStartHour
        this._nightflowEndHour = nightflowEndHour
        this._geometry = geometry
        this._connectionsMap = connections || new Map()
        this._engines = engines || new Engines([], null, [], null, [], null)
        this._normalizedConnectionsMaps = new Map()
        for (const [id, con] of this._connectionsMap) {
            this._normalizedConnectionsMaps.set(id.replaceAll('-', '').toLocaleLowerCase(), con)
        }
    }

    public get zoneId(): string {
        return this._zoneId
    }

    public get externalId(): string {
        return this._externalId
    }

    public get timezone(): string {
        return this._timezone
    }

    public get pipeLength(): number {
        return this._pipeLength
    }

    public get timeStep(): number {
        return this._timeStep
    }

    public get averageWindow(): number {
        return this._averageWindow
    }

    public get gracePeriod(): number {
        return this._gracePeriod
    }

    public get inhabitantsCount(): number | null {
        return this._inhabitantsCount
    }

    public get houseConnections(): number | null {
        return this._houseConnections
    }

    public get minimumNightFlow(): number | null {
        return this._minimumNightFlow
    }

    public get nightflowStartHour(): string | null {
        return this._nightflowStartHour
    }

    public get nightflowEndHour(): string | null {
        return this._nightflowEndHour
    }

    public get geometry(): Geometry {
        return this._geometry
    }

    public get connections(): Connection<Sensor>[] {
        return Array.from(this._connectionsMap.values())
    }

    public set connections(value: Connection<Sensor>[]) {
        this._connectionsMap = reduce(
            value,
            (acc, connection) => {
                acc.set(connection.connected.id, connection)
                return acc
            },
            new Map<string, Connection<Sensor>>()
        )
    }

    public get engines(): Engines {
        return this._engines
    }

    public set engines(value: Engines) {
        this._engines = value
    }

    public get operationalDetectEngine(): EngineConfiguration | null {
        const detectEngines = this.engines.detect || []
        const operationalDetectEngine = detectEngines.find((c) => c.id === this.engines.detectOperationalConfigurationId) || null
        return operationalDetectEngine
    }

    public hasActiveMNFEngines(): boolean {
        const detectEngines = this.engines.detect || []
        const activeMnfEngines = detectEngines.filter((e) => e.active && e.providerType === ProviderType.MNF_ENGINE)
        return activeMnfEngines.length > 0
    }

    public getSensorById(sensorId: string): Sensor | null {
        const _normalizedSensorId = sensorId.replaceAll('-', '').toLocaleLowerCase()
        const value = this._normalizedConnectionsMaps.get(_normalizedSensorId)
        if (!value) {
            return null
        }
        return value.connected
    }
}
