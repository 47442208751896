
import { Vue, Component } from 'vue-property-decorator'
import axios, { AxiosError } from 'axios'

import { IAuthService, ITranslationService, serviceLocatorInstance } from '@/domains/common/services'
import { getEnv } from '@/utils/env'

@Component
export default class App extends Vue {
    created(): void {
        this.setUpResponseInterceptor()
    }

    async mounted(): Promise<void> {
        this.useLanguage(getEnv('VUE_APP_DEFAULT_LANGUAGE'))
    }

    setUpResponseInterceptor(): void {
        const authService = serviceLocatorInstance.get<IAuthService>('auth')
        axios.interceptors.response.use(
            (response) => {
                return response
            },
            (error: AxiosError) => {
                if (error.response?.status === 401) {
                    authService.logout()
                    this.$router.push({
                        name: 'Auth'
                    })
                }
                return Promise.reject(error)
            }
        )
    }

    useLanguage(code: string): void {
        const translationService = serviceLocatorInstance.get<ITranslationService>('translation')
        translationService.useLanguage(code)
    }
}
