import { Feature, FeatureCollection, GeoJsonProperties } from 'geojson'
import { AnyLayer, MapLayerEventType } from 'mapbox-gl'
import { AllGeoJSON, pointOnFeature } from '@turf/turf'
import { flatten } from 'lodash'

import { BaseController, BaseState } from '@/domains/common/components/map/BaseController'
import { MapBoxGL, MapBoxGLEvent } from '@/domains/common/components/map/MapBoxGL'
import { LegendRepresentation } from '@/domains/common/models'

export interface DMAFeatureState extends BaseState {
    kpiSelected: string | null
    hover: boolean
}

interface SensorFeatureState {
    hover: boolean
}

type DMACallback = (dmaId: string) => void
type SensorCallback = (dmaId: string) => void
type PopupCallback = (properties: GeoJsonProperties, state: DMAFeatureState) => string | null

export class GroupMapController extends BaseController {
    private readonly dmaBackgroundColor = '#9999BB'
    private readonly textColor = '#506f9f'
    private readonly dmaBorderColor = '#607495'
    private readonly sensorColor = '#000'

    private readonly sensorZoomLevel: number = 10

    private readonly kpisLegends: LegendRepresentation[]
    private readonly popupCallback: PopupCallback
    private readonly clickOnDMACallback: DMACallback
    private readonly clickOnSensorCallback: SensorCallback
    private hoveredDMA?: string | number

    constructor(
        mapBoxGL: MapBoxGL,
        kpisLegends: LegendRepresentation[],
        sensorZoomLevel: number,
        popupCallback: PopupCallback,
        clickOnDMACallback: DMACallback,
        clickOnSensorCallback: SensorCallback
    ) {
        super(mapBoxGL)
        this.kpisLegends = kpisLegends
        this.sensorZoomLevel = sensorZoomLevel
        this.popupCallback = popupCallback
        this.clickOnDMACallback = clickOnDMACallback
        this.clickOnSensorCallback = clickOnSensorCallback
    }

    //#region Controller config
    public getSources(): string[] {
        return ['dmas', 'dma-centers', 'sensors']
    }

    public getLayers(): AnyLayer[] {
        return [
            this.buildDMAs('dmas', this.getSourceName('dmas')),
            this.buildDMAContours('dma-contours', this.getSourceName('dmas')),
            this.buildSensors('sensors', this.getSourceName('sensors')),
            this.buildSensorNames('sensor-names', this.getSourceName('sensors')),
            this.buildDmaNames('dma-names', this.getSourceName('dma-centers'))
        ]
    }

    public getLayersWithPopup(): AnyLayer[] {
        return [this.layers[0]]
    }

    public getCursors(): Map<string, string> {
        return new Map([
            ['dmas', 'pointer'],
            ['sensors', 'pointer']
        ])
    }

    public getLayerEvents(): { name: keyof MapLayerEventType; layer: string; callback: (ev: MapBoxGLEvent) => void }[] {
        return [
            {
                name: 'mousemove',
                layer: 'dmas',
                callback: (e) => this.onMouseOverDMA(e)
            },
            {
                name: 'mouseleave',
                layer: 'dmas',
                callback: () => this.onMouseOutDMA()
            },
            {
                name: 'click',
                layer: 'dmas',
                callback: (e) => this.onDMAClick(e)
            },
            {
                name: 'click',
                layer: 'sensors',
                callback: (e) => this.onSensorClick(e)
            }
        ]
    }

    public buildPopupText(properties: GeoJsonProperties, state: DMAFeatureState): string | null {
        return this.popupCallback(properties, state)
    }

    //#endregion

    //#region Public methods
    public displayDmas(dmas: FeatureCollection | null, zoomPadding = 100, zoomOnItems = true): void {
        if (!this.mapBoxGL?.isReady || !dmas) {
            return
        }

        // Update DMAs
        this.mapBoxGL.updateSource('dmas', dmas)

        // Update DMAs center
        this.mapBoxGL.updateSource('dma-centers', {
            type: 'FeatureCollection',
            features: dmas.features.map(this.buildDmaCenterFeature)
        })

        if (zoomOnItems) {
            this.mapBoxGL.zoom(dmas, zoomPadding)
        }
    }

    public focusOnPOIs(featureCollection: FeatureCollection, zoomPadding = 100): void {
        this.mapBoxGL.zoom(featureCollection, zoomPadding)
    }

    private buildDmaCenterFeature(dmaFeature: Feature): Feature {
        return {
            id: dmaFeature.id,
            type: 'Feature',
            geometry: pointOnFeature(dmaFeature.geometry as AllGeoJSON).geometry,
            properties: {
                id: dmaFeature.id,
                name: dmaFeature.properties?.name
            }
        }
    }

    public displaySensors(sensors: FeatureCollection | null, zoomPadding = 100, zoomOnItems = true): void {
        if (!this.mapBoxGL?.isReady || !sensors) {
            return
        }

        this.mapBoxGL.updateSource('sensors', sensors)

        if (zoomOnItems) {
            this.mapBoxGL.zoom(sensors, zoomPadding)
        }
    }

    public updateKpiSelected(dmas: FeatureCollection | null, kpiSelected: string | null): void {
        if (!this.mapBoxGL?.isReady || !dmas) {
            return
        }

        for (const dma of dmas.features) {
            if (dma.id === undefined) {
                continue
            }

            this.updateFeatureStateForDMA(dma.id, {
                kpiSelected: kpiSelected
            })
        }
    }

    public updateKpis(dmas: FeatureCollection | null, kpis: { [key: string]: Partial<DMAFeatureState> } | null): void {
        if (!this.mapBoxGL?.isReady || !dmas || !kpis) {
            return
        }

        for (const dma of dmas.features) {
            if (dma.id === undefined || !dma.properties) {
                continue
            }

            const newStateDma = kpis[dma.properties['id']]
            this.updateFeatureStateForDMA(dma.id, newStateDma)
        }
    }
    //#endregion

    //#region Events
    private onMouseOverDMA(event: MapBoxGLEvent): void {
        if (!this.mapBoxGL?.isReady || !event.features || event.features.length <= 0) {
            return
        }

        const feature = event.features[0]
        if (feature.id === undefined) {
            return
        }

        this.overDMA(feature.id)
    }

    private overDMA(dmaId: string | number): void {
        if (dmaId === this.hoveredDMA) {
            return
        }

        if (this.hoveredDMA !== undefined) {
            this.updateFeatureStateForDMA(this.hoveredDMA, {
                hover: false
            })
        }

        this.updateFeatureStateForDMA(dmaId, {
            hover: true
        })
        this.hoveredDMA = dmaId
    }

    private onMouseOutDMA(): void {
        if (this.hoveredDMA !== undefined) {
            this.updateFeatureStateForDMA(this.hoveredDMA, {
                hover: false
            })
            this.hoveredDMA = undefined
        }
    }

    private onDMAClick(event: MapBoxGLEvent): void {
        if (!this.mapBoxGL?.isReady || !event.features || event.features.length <= 0) {
            return
        }
        const feature = event.features[0]
        if (feature.properties?.id) {
            this.clickOnDMACallback(feature.properties.id)
        }
    }

    private onSensorClick(event: MapBoxGLEvent): void {
        if (!this.mapBoxGL?.isReady || !event.features || event.features.length <= 0) {
            return
        }
        const feature = event.features[0]
        if (feature.properties?.id) {
            this.clickOnSensorCallback(feature.properties.id)
        }
    }

    private updateFeatureStateForDMA(dmaId: string | number, state: Partial<DMAFeatureState>): void {
        if (!this.mapBoxGL?.isReady) {
            return
        }

        this.mapBoxGL.updateFeatureState('dmas', dmaId, state)
        this.mapBoxGL.updateFeatureState('dma-centers', dmaId, state)
    }

    private updateFeatureStateForSensor(sensorId: string | number, state: Partial<SensorFeatureState>): void {
        if (!this.mapBoxGL?.isReady) {
            return
        }

        this.mapBoxGL.updateFeatureState('sensors', sensorId, state)
    }
    //#endregion

    //#region Layers
    private buildDMAs(name: string, source: string): AnyLayer {
        return {
            id: name,
            type: 'fill',
            source: source,
            paint: {
                'fill-color': [
                    'match',
                    ['feature-state', 'kpiSelected'],
                    ...flatten([
                        ...this.kpisLegends.map((legend) => [
                            legend.id,
                            legend.getLayerExpressionNoEngine(this.dmaBackgroundColor)
                        ])
                    ]),
                    this.dmaBackgroundColor
                ],
                'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.8, 0.4]
            }
        }
    }

    private buildDMAContours(name: string, source: string): AnyLayer {
        return {
            id: name,
            type: 'line',
            source: source,
            paint: {
                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 2, 1],
                'line-color': this.dmaBorderColor
            }
        }
    }

    private buildDmaNames(name: string, source: string): AnyLayer {
        return {
            id: name,
            type: 'symbol',
            source: source,
            minzoom: 10,
            layout: {
                'text-field': ['get', 'name'],
                'text-allow-overlap': true,
                'text-anchor': 'top',
                'text-font': ['DIN Pro Medium'],
                'text-size': 14
            },
            paint: {
                'text-color': this.textColor,
                'text-halo-blur': 1,
                'text-halo-width': 1,
                'text-halo-color': '#fff',
                'text-opacity': [
                    'interpolate',
                    ['exponential', 2],
                    ['zoom'],
                    9,
                    ['case', ['boolean', ['feature-state', 'hover'], false], 1.0, 0.0],
                    12,
                    ['case', ['boolean', ['feature-state', 'hover'], false], 1.0, 0.75]
                ]
            }
        }
    }

    private buildSensors(name: string, source: string): AnyLayer {
        return {
            id: name,
            type: 'circle',
            source: source,
            minzoom: this.sensorZoomLevel,
            paint: {
                'circle-color': this.sensorColor,
                'circle-radius': 8
            }
        }
    }

    private buildSensorNames(name: string, source: string): AnyLayer {
        return {
            id: name,
            type: 'symbol',
            source: source,
            minzoom: 10,
            layout: {
                'text-field': ['get', 'name'],
                'text-font': ['DIN Pro Medium'],
                'text-size': 11,
                'text-offset': [0, 1],
                'text-rotation-alignment': 'auto',
                'text-allow-overlap': false,
                'text-anchor': 'top'
            },
            paint: {
                'text-color': this.textColor,
                'text-halo-blur': 1,
                'text-halo-width': 1,
                'text-halo-color': '#fff',
                'text-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0, 9, 1]
            }
        }
    }
    //#endregion
}
