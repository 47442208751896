const onCopyClicked = (el: HTMLElement, icon: HTMLElement): void => {
    const content = el.textContent
    if (content) {
        navigator.clipboard.writeText(content)
        icon.classList.replace('fa-clipboard', 'fa-circle-check')
        icon.classList.replace('far', 'fas')
        icon.classList.add('has-text-success')
        setTimeout(() => {
            icon.classList.replace('fas', 'far')
            icon.classList.replace('fa-circle-check', 'fa-clipboard')
            icon.classList.remove('has-text-success')
        }, 1000)
    }
}

const appendIcon = (el: HTMLElement) => {
    const parentContainer = el.parentElement
    if (!parentContainer) {
        return
    }

    // Create a wrapper to let the icon be relative to it
    const wrapper = document.createElement('div')
    wrapper.classList.add('clipboard-support')

    const span = document.createElement('span')
    span.classList.add('icon')
    span.classList.add('clipboard-icon')

    const icon = document.createElement('i')
    icon.classList.add('far', 'fa-clipboard')

    span.addEventListener('click', () => onCopyClicked(el, icon))

    span.appendChild(icon)
    wrapper.appendChild(span)
    wrapper.appendChild(el)
    parentContainer.appendChild(wrapper)
}

export const toClipboard = {
    inserted: (el: HTMLElement): void => {
        appendIcon(el)
    }
}
