import { RawLocation, RouteConfig } from 'vue-router'

const TasksView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "tasks" */ '../containers/TasksView.vue')
const TasksOverview = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "tasks" */ '../containers/TasksOverview.vue')

export const routes: Array<RouteConfig> = [
    {
        path: 'overview',
        name: 'TasksOverview',
        component: TasksOverview
    },
    {
        path: '',
        redirect: (): RawLocation => {
            return { name: 'TasksOverview' }
        }
    }
]

export const container = TasksView
