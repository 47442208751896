import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { container as authContainer, routes as authRoutes } from '@/domains/auth/router'
import { RoleEnum } from '@/domains/common/models'
import { container as dataManagementContainer, routes as dataManagementRoutes } from '@/domains/data-management/router'
import { container as detectContainer, routes as detectRoutes } from '@/domains/detect/router'
import { container as monitorContainer, routes as monitorRoutes } from '@/domains/monitor/router'
import { container as networkContainer, routes as networkRoutes } from '@/domains/network/router'
import { container as usageContainer, routes as usageRoutes } from '@/domains/usage/router'
import { container as tasksContainer, routes as tasksRoutes } from '@/domains/tasks/router'

import authenticationGuard from './guards/AuthenticationGuard'

const Secure = () => import('@/domains/common/containers/Secure.vue')

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/auth',
        component: authContainer,
        children: authRoutes
    },
    {
        path: '/:organization',
        component: Secure,
        props: true,
        redirect: { name: 'Detect' },
        children: [
            {
                path: 'data',
                component: dataManagementContainer,
                children: dataManagementRoutes,
                meta: {
                    requiresRole: RoleEnum.OPERATOR
                }
            },
            {
                path: 'detect',
                component: detectContainer,
                children: detectRoutes
            },
            {
                path: 'monitor',
                component: monitorContainer,
                children: monitorRoutes,
                meta: {
                    requiresRole: RoleEnum.ORGANIZATION_ADMINISTRATOR
                }
            },
            {
                path: 'network',
                component: networkContainer,
                children: networkRoutes,
                meta: {
                    requiresRole: RoleEnum.MANAGER
                }
            },
            {
                path: 'usage',
                component: usageContainer,
                children: usageRoutes,
                meta: {
                    requiresRole: RoleEnum.ORGANIZATION_ADMINISTRATOR
                }
            },
            {
                path: 'tasks',
                component: tasksContainer,
                children: tasksRoutes,
                meta: {
                    requiresRole: RoleEnum.ORGANIZATION_ADMINISTRATOR
                }
            }
        ],
        meta: {
            requiresAuthenticated: true
        }
    },
    {
        path: '',
        name: 'Secure',
        component: Secure,
        meta: {
            requiresAuthenticated: true
        }
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition
        } else {
            const content = document.querySelector('#content')
            if (content) {
                content.scrollTo({ top: 0 })
            }

            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach(authenticationGuard)

export default router
